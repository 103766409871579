import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from '../components/toaster/toaster.component';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(public snackbar: MatSnackBar) { }

  /**
   * Displays a toast notification with the given message and type.
   *
   * @param {string} message - The message to be displayed in the toast notification.
   * @param {string} type - The type of the toast notification (e.g. 'Close', 'Warning', etc.). Defaults to 'Close'.
   * @param {number} duration - The duration of the toast notification in milliseconds. Defaults to 3000.
   * @return {void}
   */
  showToast(message: string, type: string = 'Close', duration: number = 3000) {
    this.snackbar.openFromComponent(ToasterComponent, {
      data:{
        message: message,
        type: type
      },
      duration: duration,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
}
